
import {
  defineComponent,
  ref,
  Ref,
  onMounted,
  reactive,
  nextTick,
  onUnmounted,
  inject,
} from "vue";
import { useI18n } from "vue-i18n";
import moment from "moment";
import {
  UpOutlined,
  DownOutlined,
  CheckOutlined,
  PlusCircleFilled,
} from "@ant-design/icons-vue";
import AvailabilityFilters from "../components/AvailabilityFilters.vue";
import { useShowErrors } from "@hd2/common/src/composable/useShowErrors";
import { ResponseList, Availability, AvailabilityStatus } from "../../types";
import { deepIterate } from "@hd2/common/src/utils";
import { get, set, unset, isEmpty } from "lodash";
import { useStore } from "../store";
import { notification } from "ant-design-vue";
import { AxiosStatic } from "axios";

interface GetAvailabilitiesPayload {
  from: moment.Moment;
  to: moment.Moment;
  visitType: string;
}

export const AvailabilityComponent = defineComponent({
  components: {
    UpOutlined,
    DownOutlined,
    CheckOutlined,
    PlusCircleFilled,
    AvailabilityFilters,
  },
  setup() {
    const { t } = useI18n();
    const http = inject("http") as AxiosStatic;
    const { showErrors } = useShowErrors();
    const store = useStore();
    const calendarData: Ref<Availability> = ref({});
    const daysBetween: Ref<Array<moment.Moment>> = ref([]);
    const dayTime: Ref<Array<moment.Moment>> = ref([]);
    const ifMoreHoursWereShown: Ref<boolean> = ref(false);
    const editMode: Ref<boolean> = ref(false);
    const editedCalendarData: Ref<{ [key: string]: AvailabilityStatus }> = ref(
      {}
    );
    const isEditModeDisabled: Ref<boolean> = ref(true);
    const getAvailabilitiesPayload: GetAvailabilitiesPayload = reactive({
      from: moment(),
      to: moment(),
      visitType: "HOUSE",
    });

    const isCancelEditModalVisible: Ref<boolean> = ref(false);
    const availabilitiesLoaded: Ref<boolean> = ref(false);
    const showSpinner: Ref<boolean> = ref(false);
    const isEditingDisabled: Ref<boolean> = ref(false);

    const availabilityCalendar = ref();

    const createDaysBetween = (
      startDate: moment.Moment,
      endDate: moment.Moment
    ): void => {
      const dates = [];

      const currDate = moment(startDate).startOf("day").add(-1, "days");
      const lastDate = moment(endDate).startOf("day");

      while (currDate.add(1, "days").diff(lastDate) <= 0) {
        dates.push(currDate.clone());
      }

      daysBetween.value = dates;
    };

    const createHoursBetween = (): void => {
      for (let hour = 0; hour < 24; hour++) {
        dayTime.value.push(moment({ hour: hour }));
      }
    };

    const loadTempAvailabilities = (): Availability => {
      const objList: Availability = {};
      const objTimes: Record<
        string,
        { availabilityStatus: AvailabilityStatus; checked: boolean }
      > = {};
      for (let hour = 0; hour < 24; hour++) {
        objTimes[moment({ hour: hour }).format("HH:mm")] = JSON.parse(
          JSON.stringify({ availabilityStatus: "NONE", checked: false })
        );
      }
      daysBetween.value.forEach((key) => {
        objList[key.format("YYYY-MM-DD")] = JSON.parse(
          JSON.stringify(objTimes)
        );
      });

      return objList;
    };

    const stickyAvailabilityCalendarHeader = () => {
      const availabilityPositionTop: number =
        availabilityCalendar.value.getBoundingClientRect().top;
      if (availabilityPositionTop < window.scrollY) {
        availabilityCalendar.value
          .querySelector(".calendar-date__wrapper")
          .classList.add("calendar-date__wrapper--sticky");
      } else {
        availabilityCalendar.value
          .querySelector(".calendar-date__wrapper")
          .classList.remove("calendar-date__wrapper--sticky");
      }
    };

    const onAvailabilitiesTableVisible = () => {
      window.addEventListener("scroll", stickyAvailabilityCalendarHeader);
    };

    const getAvailabilities = async ({
      from,
      to,
      visitType,
    }: GetAvailabilitiesPayload) => {
      if (to && from && visitType) {
        getAvailabilitiesPayload.to = to;
        getAvailabilitiesPayload.from = from;
        getAvailabilitiesPayload.visitType = visitType;
        showSpinner.value = true;

        const link = `v1/doctors/${
          store.state.user.id
        }/calendars?from=${from.format("YYYY-MM-DD")}&to=${to.format(
          "YYYY-MM-DD"
        )}&visitType=${visitType}`;

        try {
          const availabilityRes = await http
            .get(link)
            .then((res): ResponseList<Availability> => res.data);
          createDaysBetween(
            moment(from, "YYYY-MM-DD"),
            moment(to, "YYYY-MM-DD")
          );
          calendarData.value = loadTempAvailabilities();

          deepIterate<Availability>(calendarData.value, (keys) => {
            if (keys[2] === "availabilityStatus") {
              const value = get(availabilityRes, keys.slice(0, -1));
              if (value) {
                set(calendarData.value, keys, value);
              } else {
                set(calendarData.value, keys, "NONE");
              }
            }
          });

          availabilitiesLoaded.value = true;
          await nextTick();
          onAvailabilitiesTableVisible();
        } catch (err: any) {
          showErrors(err.response?.data);
          showSpinner.value = false;
        } finally {
          showSpinner.value = false;
          isEditModeDisabled.value = false;
        }
      }
    };

    const resetEditingStatuses = (): void => {
      deepIterate<Availability>(calendarData.value, (keys) => {
        if (keys[2] === "checked") {
          set(calendarData.value, keys, false);
        }
      });
      editedCalendarData.value = {};
    };

    const save = async () => {
      isEditingDisabled.value = true;
      try {
        await http.patch(
          `v1/doctors/${store.state.user.id}/calendars`,
          editedCalendarData.value
        );
      } catch (err: any) {
        showErrors(err.response?.data);
      } finally {
        editMode.value = false;
        isEditingDisabled.value = false;
        notification.open({
          message: t("SAVED_SUCCESSFULLY"),
          class: "success",
        });
        resetEditingStatuses();
        await getAvailabilities(getAvailabilitiesPayload);
      }
    };

    const cancelEdit = (): void => {
      editMode.value = false;
      resetEditingStatuses();
      isCancelEditModalVisible.value = !isCancelEditModalVisible.value;
    };

    const checkActualDateTime = (dateTime: moment.Moment): boolean => {
      return moment() < moment(dateTime).add(1, "hours");
    };

    const changeStatus = (day: string, hour: string): void => {
      if (checkActualDateTime(moment(day + hour, "YYYY-MM-DDHH:mm"))) {
        if (get(calendarData.value, [day, hour, "checked"])) {
          unset(editedCalendarData.value, [
            getAvailabilitiesPayload.visitType,
            day,
            hour,
          ]);
          set(calendarData.value, [day, hour, "checked"], false);
          if (
            isEmpty(
              get(editedCalendarData.value, [
                getAvailabilitiesPayload.visitType,
                day,
              ])
            )
          ) {
            unset(editedCalendarData.value, [
              getAvailabilitiesPayload.visitType,
              day,
            ]);
          }
        } else {
          set(
            editedCalendarData.value,
            [getAvailabilitiesPayload.visitType, day, hour],
            "PLANNED"
          );
          set(calendarData.value, [day, hour, "checked"], true);
        }
      }
    };

    onMounted(() => {
      createHoursBetween();
    });
    onUnmounted(() => {
      window.removeEventListener("scroll", stickyAvailabilityCalendarHeader);
    });
    return {
      t,
      getAvailabilities,
      daysBetween,
      calendarData,
      dayTime,
      ifMoreHoursWereShown,
      moment,
      editMode,
      save,
      cancelEdit,
      changeStatus,
      isCancelEditModalVisible,
      availabilitiesLoaded,
      showSpinner,
      isEditModeDisabled,
      isEditingDisabled,
      checkActualDateTime,
      availabilityCalendar,
    };
  },
});
export default AvailabilityComponent;
