
import {
  defineComponent,
  onMounted,
  ref,
  Ref,
  reactive,
  computed,
  inject,
} from "vue";
import { useShowErrors } from "@hd2/common/src/composable/useShowErrors";
import { useI18n } from "vue-i18n";

import moment from "moment";
import {
  CloseOutlined,
  LeftOutlined,
  RightOutlined,
  CalendarOutlined,
  CaretDownOutlined,
} from "@ant-design/icons-vue";
import { AxiosStatic } from "axios";

interface VisitType {
  value: string;
  label: string;
}

interface GetAvailabilitiesPayload {
  from: moment.Moment | null;
  to: moment.Moment | null;
  visitType: string | null;
}

export const AvailabilityFiltersComponent = defineComponent({
  emits: ["change", "switchedToEditMode", "afterCancel", "save"],
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    isEditModeDisabled: {
      Boolean,
      default: true,
    },
    isEditingDisabled: {
      Boolean,
      default: false,
    },
  },
  components: {
    CloseOutlined,
    RightOutlined,
    LeftOutlined,
    CalendarOutlined,
    CaretDownOutlined,
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const http = inject("http") as AxiosStatic;
    const { showErrors } = useShowErrors();
    const date: Ref<moment.Moment> = ref(moment());
    const visitsTypes: Ref<VisitType[]> = ref([]);
    const dateOutput = computed(
      () =>
        `${moment(date.value).startOf("week").format("DD.MM.YYYY")} - ${moment(
          date.value
        )
          .endOf("week")
          .format("DD.MM.YYYY")}`
    );

    const getAvailabilitiesPayload: GetAvailabilitiesPayload = reactive({
      from: moment().startOf("week"),
      to: moment().endOf("week"),
      visitType: null,
      availabilitiesLoaded: false,
    });

    const getVisitsTypes = async () => {
      const url = `v1/visits/types`;
      try {
        const { data } = await http.get(url);
        visitsTypes.value = data.map((visitType: string) => {
          return {
            value: visitType,
            label: t(`VISITS_TYPES.${visitType}`),
          };
        });
      } catch (err: any) {
        showErrors(err);
      }
    };

    const changeDate = (newDate: string | moment.Moment): void => {
      date.value = moment(newDate);
      const chosenDate = moment(newDate);

      const from = moment(chosenDate.startOf("week"));
      const to = moment(chosenDate.endOf("week"));

      getAvailabilitiesPayload.from = moment(from);
      getAvailabilitiesPayload.to = moment(to);
      emit("change", getAvailabilitiesPayload);
    };

    const changeVisitType = (visitType: string): void => {
      getAvailabilitiesPayload.visitType = visitType;
      emit("change", getAvailabilitiesPayload);
    };

    const nextWeek = (): void => {
      const newDate = moment(date.value.add(7, "days"));
      date.value = newDate;
      changeDate(newDate);
    };

    const previousWeek = (): void => {
      const newDate = moment(date.value.subtract(7, "days"));
      date.value = newDate;
      changeDate(newDate);
    };

    onMounted(async () => {
      await getVisitsTypes();
    });

    return {
      visitsTypes,
      t,
      changeDate,
      changeVisitType,
      date,
      previousWeek,
      nextWeek,
      LeftOutlined,
      RightOutlined,
      CaretDownOutlined,
      getAvailabilitiesPayload,
      dateOutput,
      CalendarOutlined,
    };
  },
});
export default AvailabilityFiltersComponent;
